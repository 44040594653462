import classes from "./LoginScreen.module.css";
import { userContext } from "../../services/userContext";
import { useContext, useState, useEffect, useRef } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { get, post } from "../../services/api";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import Logo from "../../assets/images/scoring-logo-login.png";
import { Link } from "react-router-dom";
import { Input } from "../../components/LoginInput/input";
import Mail from "../../assets/Icons/mail.png";
import Password from "../../assets/Icons/lock.png";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { translate } from "../../utils/translate";
import { Box } from "@mui/material";
import { icons } from "../../lib/icons";
import Cookies from "js-cookie";

const LoginScreen = () => {
  //setovanje korisnika i tokena
  const { login, isLoggedIn, lang } = useContext(userContext);
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();
  const hash = searchParams?.get("hash");
  const back_url = searchParams?.get("back_url");

  // redirekt ukoliko je korisnik ulogovan
  useEffect(() => {
    isLoggedIn && pathname === "/login" && navigate("/dashboard");
  }, [isLoggedIn]);

  //setovanje login podataka koji se salju na API
  const [loginData, setLoginData] = useState({
    email: "",
    password: "",
  });

  //setovanje podataka za resetovanje lozinke
  const [passwordReset, setPasswordReset] = useState({
    email: "",
  });
  //setovanje loadinga nakon klika na prijavu
  const [loading, setLoading] = useState(false);

  //modal za zaboravljenu lozinku
  const [modal, setModal] = useState(false);

  //fokusuje modal nakon klika na zaboravljena lozinka
  const modalRef = useRef(null);
  useEffect(() => {
    modalRef.current.focus();
  }, [modal]);

  //funkcija za logovanje korisnika na portal
  const handleLogin = useMutation({
    mutationKey: ["login", loginData],
    mutationFn: async (loginData) =>
      await post("/portal/sign-in/login", loginData).then((res) => {
        if (res?.code === 200) {
          login(res?.payload?.user, res?.payload?.access_token, back_url);
          toast.success(["Uspešno ste se ulogovali!"], {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
          });
        } else {
          setLoginData({
            email: "",
            password: "",
          });
          toast.error(res?.data?.message, {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
          });
        }
      }),
  });

  //funckija za resetovanje lozinke
  const handlePasswordReset = useMutation({
    mutationKey: ["passwordReset", passwordReset],
    mutationFn: async (passwordReset) => {
      return await post("/portal/sign-in/forgot-password", passwordReset)
        .then((res) => {
          if (res?.code === 200) {
            setLoading(false);
            toast.success(res?.payload?.message, {
              position: "top-center",
              autoClose: 4000,
              style: { marginTop: "1rem" },
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
            });
            setPasswordReset({
              email: "",
            });
            setModal(false);
          } else {
            toast.error(res?.data?.message, {
              position: "top-center",
              autoClose: 3000,
              style: { marginTop: "1rem" },
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
            });
            setPasswordReset({
              email: "",
            });
          }
        })
        .catch((err) => {
          toast.error(["Došlo je do greške!"], {
            position: "top-center",
            autoClose: 3000,
            style: { marginTop: "1rem" },
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
          });
          setLoading(false);
        });
    },
  });

  const emailTest = (email) => {
    const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return re.test(email);
  };

  const handleLogIn = () => {
    switch (true) {
      case !emailTest(loginData.email) && loginData.password?.length > 0:
        toast.error("Unesite validan email!", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
        });
        break;

      case emailTest(loginData.email) && !loginData.password:
        toast.error("Unesite lozinku!", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
        });
        break;
      case !emailTest(loginData.email) && !loginData.password:
        toast.error("Unesite validan email i lozinku!", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
        });
        break;
      default:
        handleLogin.mutate(loginData);
        break;
    }
  };

  const { refetch: handleLoginViaHash } = useQuery({
    queryKey: ["hash", hash],
    queryFn: async () => {
      return await get(`/portal/sign-in/impersonate/${hash}`).then((res) => {
        if (res?.code === 200) {
          Cookies.set("device-token", res?.payload?.user?.device_token);
          Cookies.set("isFromAdmin", res?.payload?.user?.is_from_admin);
          login(res?.payload?.user, res?.payload?.access_token, back_url);
        } else {
          toast.error(
            res?.data?.message ?? "Došlo je do greške! Pokušajte ponovo!",
            {
              position: "top-center",
              autoClose: 2000,
            },
          );
        }
      });
    },
    enabled: false,
  });

  useEffect(() => {
    if (hash) {
      handleLoginViaHash();
    }
  }, [hash]);

  return (
    <Box className={classes.bg}>
      <Box
        className={
          handleLogin?.isPending ? classes.modal_show : classes.modal_hide
        }
      >
        {icons?.loading}
      </Box>
      <Box
        className={`ml-[11.63%] bg-[#232f3e] w-[31%] 3xl:w-[28.25%] rounded-b-3xl`}
      >
        <Box className={`mx-[31.67%] pt-[8.62%]`}>
          <Link to={"/"}>
            <img src={Logo} />
          </Link>
        </Box>
        <form
          className={`mt-[9%] mx-[8%] flex flex-col xl:gap-[1.1rem] 3xl:gap-[2.5rem]`}
          onSubmit={(event) => {
            event.preventDefault();
            setLoading(true);
            handleLogin.mutate({
              ...loginData,
            });
          }}
        >
          {/*E-mail input*/}
          <Input
            type={"email"}
            data={loginData}
            setData={setLoginData}
            image={Mail}
            label={"Unesite e-mail:"}
            name={"email"}
            id={"user-email"}
          />
          {/*Password input*/}
          <Input
            type={"password"}
            data={loginData}
            setData={setLoginData}
            image={Password}
            label={"Unesite lozinku:"}
            name={"password"}
            id={"user-password"}
          />
          <button
            className={`bg-[#aa1d23] rounded-xl uppercase text-white font-semibold hover:bg-opacity-80 xl:h-[50px] 3xl:h-[60px]`}
            onClick={(event) => {
              event.preventDefault();
              setLoading(true);
              handleLogIn();
            }}
          >
            {["Prijavite se"]}
          </button>
          <Box className={`flex justify-between`}>
            <Box className={`flex items-center gap-2`}>
              <input
                type={`checkbox`}
                className={`bg-white/30 rounded h-5 w-5 text-[#aa1d23] focus:ring-0 focus:outline-none focus:border-0`}
                id={`passwordSave`}
              />
              <label
                htmlFor={`passwordSave`}
                className={`text-white text-[.9rem] font-light`}
              >
                {["Sačuvajte lozinku"]}
              </label>
            </Box>
            <p
              onClick={() => setModal(true)}
              className={`text-white cursor-pointer text-[.9rem]  font-light hover:underline`}
            >
              {["Zaboravili ste lozinku?"]}
            </p>
          </Box>
        </form>
        <Box
          className={`mx-[21.67%] xl:mt-[5%] 3xl:mt-[10%] flex flex-col items-center justify-center gap-5`}
        >
          <Link
            to={`/registracija`}
            className={`relative text-white text-[1.2rem] font-medium borderb`}
          >
            {["Prijavite se za DEMO nalog"]}
          </Link>
        </Box>
        <Box
          className={`mt-[15%] pb-7 w-full relative separate flex justify-center items-center text-white text-sm font-light`}
        >
          <Link className={`relative hover:underline`} to={`/kontakt`}>
            {["Kontakt"]}
          </Link>
          <Link
            className={`relative hover:underline`}
            to={`/politika-privatnosti`}
          >
            {["Politika privatnosti"]}
          </Link>
          <Link className={`relative hover:underline`} to={`/o-nama`}>
            {["O nama"]}
          </Link>
        </Box>
      </Box>
      <Box
        tabIndex={1}
        ref={modalRef}
        onKeyDown={(e) => {
          if (e.key === "Escape") {
            setModal(false);
          }
        }}
        onClick={(e) => {
          if (e.target === e.currentTarget) {
            setModal(false);
          }
        }}
        className={
          modal
            ? `fixed  z-1 top-0 left-0 w-screen h-screen bg-black/40 flex items-center justify-center`
            : `hidden`
        }
      >
        <Box
          className={`bg-[#282e3d] shadow-xl min-h-[315px] relative z-10 w-[35%] h-[35%] rounded-2xl pointer-events-auto`}
        >
          <Box
            className={`absolute text-white top-0 right-0 w-[2.5rem] h-[2.5rem] flex items-center justify-center cursor-pointer`}
            onClick={() => setModal(false)}
          >
            {icons?.close}
          </Box>
          <Box
            className={`flex flex-col flex-1 items-start p-10 justify-center gap-5`}
          >
            <h1 className={`text-white text-xl font-medium`}>
              {["Zaboravljena lozinka"]}
            </h1>

            <p className={`text-white text-[15px] font-normal`}>
              {[
                "Ukoliko ste zaboravili Vašu lozinku, potrebno je da unesete Vaš e-mail, na koji ćemo Vam proslediti link sa daljim uputstvom za promenu lozinke.",
              ]}
            </p>
            <Input
              type={`email`}
              name={`email`}
              data={passwordReset}
              id={`email`}
              setData={setPasswordReset}
              label={"Unesite e-mail:"}
              isReset={false}
              image={Mail}
            />
            <button
              disabled={handlePasswordReset.isPending}
              className={`bg-[#aa1d23] w-full text-center flex justify-center items-center rounded-xl uppercase text-white font-semibold hover:bg-opacity-80 py-3.5 disabled:bg-opacity-50`}
              onClick={(event) => {
                event.preventDefault();
                if (!emailTest(passwordReset.email)) {
                  toast.error("Unesite validan email!", {
                    position: "top-center",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                  });
                } else {
                  handlePasswordReset.mutate(passwordReset);
                }
              }}
            >
              {handlePasswordReset?.isPending ? icons?.loading : ["Pošalji"]}
            </button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default LoginScreen;
