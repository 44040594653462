import Logo from "../../assets/images/scoring-logo.png";
import Search from "../../assets/Icons/search.png";
import Filters from "../../assets/Icons/filter.png";
import User from "../../assets/Icons/user.png";
import Chat from "../../assets/Icons/chat (1).png";
import Widget from "../../assets/Icons/application.png";
import Chevron from "../../assets/Icons/right-chevron.png";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useContext, useEffect, useRef, useState } from "react";
import { userContext } from "../../services/userContext";
import { toast, ToastContainer } from "react-toastify";
import { AdvancedSearch } from "./advanced-search";
import { Button } from "@mui/material";
import { useMutation, useQuery } from "@tanstack/react-query";
import { get, post } from "../../services/api";
import Cookies from "js-cookie";
import { useTranslate } from "../../services/hooks";
export const Header = () => {
  const { user } = useContext(userContext);
  const [portalUser, setPortalUser] = useState(
    JSON.parse(Cookies.get("scoring_user")),
  );

  useEffect(() => {
    setPortalUser(JSON.parse(Cookies.get("scoring_user")));
  }, [Cookies.get("scoring_user")]);

  const [searchTerm, setSearchTerm] = useState("");
  const navigate = useNavigate();

  const handleSearch = (e) => {
    e.preventDefault();
    if (searchTerm?.length >= 3) {
      navigate(`/pretraga?search=${searchTerm}`);
      setSearchTerm("");
    } else {
      toast.error("Pretraga mora imati najmanje 3 karaktera!", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
      });
    }
  };

  const [open, setOpen] = useState(false);

  const [openMenu, setOpenMenu] = useState(false);
  const { logout } = useContext(userContext);
  const menu_ref = useRef();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        menu_ref.current &&
        !menu_ref.current.contains(event.target) &&
        event.target.closest("#menu") === null
      ) {
        setOpenMenu(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [openMenu]);

  const { mutate: log_out, isPending } = useMutation({
    mutationKey: ["logout"],
    mutationFn: async () => {
      return await post(`/portal/profile/user/logout`).then((res) => {
        switch (res?.code) {
          case 200:
            logout(null, null);
            toast.success("Uspešno ste se odjavili!", {
              position: "top-center",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
            });
            break;
          default:
            toast.error("Greška prilikom odjave!", {
              position: "top-center",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
            });
            break;
        }
      });
    },
  });

  const { data, isFetching } = useQuery({
    queryKey: ["languages_ddl"],
    queryFn: async () => {
      return await get(
        `/portal/translations/ddl/languages`,
        "TRANSLATIONS_LIST",
      )
        ?.then((res) => res?.payload)
        ?.catch((err) => console.log(err));
    },
    refetchOnWindowFocus: false,
  });
  const { lang, setLang } = useContext(userContext);

  const onParamsSearchChange = (value) => {
    Cookies.set("selected_lang", value);
    setLang(value);
  };

  const {
    data: translate_data,
    isPending: is_pending,
    isSuccess,
  } = useTranslate("header", "HEADER");

  return (
    <>
      <div
        className={`sticky top-0 bg-[#232f3e] z-[49] px-[2.75rem] py-[0.7rem]`}
      >
        <div className={`flex flex-row items-center justify-between flex-wrap`}>
          <div className={``}>
            <Link to={`/dashboard`} className={`w-full`}>
              <img
                src={Logo}
                alt="logo"
                className={`w-[240px] @[1550px]:w-[280px]`}
              />
            </Link>
          </div>
          <div className={`mr-auto ml-[8rem]`}>
            <form
              className={`relative flex items-center gap-2 m-auto`}
              onSubmit={handleSearch}
            >
              <div className={`relative flex-shrink-0 grow-0`}>
                <input
                  placeholder={`${translate_data?.["header"]?.["default"]?.["header"]?.["search_input"]?.["placeholder"] ?? "Brza pretraga"}`}
                  value={searchTerm}
                  onInput={(e) => setSearchTerm(e.target.value)}
                  type={`text`}
                  className={`bg-white/40 text-white placeholder:text-white text-base w-[19rem] @[1550px]:w-[23.125rem] h-[3.1rem] rounded-xl focus:border-[#aa1d23] focus:ring-0 focus:outline-0`}
                />
                <div
                  className={`bg-[#aa1d23] h-full absolute top-1/2 right-0 px-4 hover:bg-opacity-80 cursor-pointer rounded-xl transform -translate-y-1/2`}
                  onClick={handleSearch}
                >
                  <img
                    src={Search}
                    alt="search"
                    className={`invert relative top-[0.7rem] w-[25px]`}
                  ></img>
                </div>
              </div>
              <div
                className={`relative bg-white/40 self-stretch rounded-xl w-[3.375rem] flex items-center justify-center cursor-pointer hover:bg-[#aa1d23]`}
                onClick={() => setOpen(!open)}
              >
                <img src={Filters} className={`invert p-[0.3rem] w-[40px]`} />
              </div>
            </form>
          </div>
          <div
            onClick={() => {
              setOpenMenu(true);
            }}
            aria-checked={openMenu}
            className={`flex-grow-0 header self-end flex pl-3 pr-3 py-1 items-center gap-1 bg-white/40 rounded-xl hover:bg-[#aa1d23] aria-checked:!bg-[#aa1d23] cursor-pointer relative`}
          >
            <div>
              <img src={User} width={35} className={`invert`} />
            </div>
            <div className={`gap-0 flex flex-col`}>
              <p className={`font-medium text-base text-white uppercase`}>
                {translate_data?.["header"]?.["default"]?.["header"]?.[
                  "my_account"
                ]?.["label"] ?? "MOJ NALOG"}
              </p>
              <p className={`text-sm text-white`}>
                {portalUser?.first_name} {portalUser?.last_name}
              </p>
            </div>

            <div
              ref={menu_ref}
              id={`menu`}
              className={
                openMenu
                  ? `opacity-100 shadow rounded-xl p-3 bg-white mt-1 z-10 visible scale-100 absolute top-full left-0 w-full transition-all duration-300`
                  : `opacity-0 shadow rounded-xl p-3 bg-white mt-1 z-10 invisible scale-95 absolute top-full left-0 w-full transition-all duration-300`
              }
            >
              <Button
                className={`w-full`}
                disabled={isPending}
                variant={`contained`}
                color={`error`}
                onClick={() => {
                  log_out();
                }}
              >
                {translate_data?.["header"]?.["default"]?.["header"]?.[
                  "my_account_logout_button"
                ]?.["label"] ?? "Odjavite se"}
              </Button>
              <select
                className={`cursor-pointer border rounded-md border-[#f2f2f2] w-full text-base text-black focus:outline-0 focus:ring-0 mt-1.5`}
                onChange={(e) => {
                  onParamsSearchChange(e.target.value);
                }}
                defaultValue={lang}
              >
                {(data ?? [])?.map(({ id }) => {
                  let code = (id ?? "_")?.split("_");
                  let name = code?.join("-");
                  return (
                    <option
                      key={`value-${id}`}
                      className={`cursor-pointer uppercase`}
                      value={id}
                    >
                      {name}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
        </div>
      </div>
      {open && <AdvancedSearch setOpen={setOpen} open={open} />}
      <button
        id={`modalOpen`}
        onClick={() => setOpen(true)}
        className={`invisible opacity-0 fixed -top-[100rem] -left-[100rem]`}
      ></button>
    </>
  );
};
