import axios from "axios";
import Cookies from "js-cookie";

const generateDeviceToken = () => {
  return "device_" + Math.random().toString(12) + Date.now();
};

const getDeviceToken = () => {
  let device_token = Cookies.get("device-token");
  if (!device_token) {
    device_token = generateDeviceToken();
    Cookies.set("device-token", device_token, { expires: 365 });
  }
  return device_token;
};

const getCustomerToken = () => {
  let customer_token = Cookies.get("customer-token");

  if (!customer_token) {
    customer_token = getDeviceToken();
    Cookies.set("customer-token", customer_token, { expires: 365 });
  }
  return customer_token;
};

const getAccessToken = () => {
  return Cookies.get("scoring_token");
};

const getIsFromAdmin = () => {
  return Cookies.get("isFromAdmin");
};

const getLang = () => {
  return Cookies.get("selected_lang");
};

const makeRequest = async (method, path, payload, screen_code) => {
  const token = getAccessToken();
  const lang = getLang();
  const is_from_admin = getIsFromAdmin();
  const headers = {
    "customer-token": getCustomerToken(),
    "device-token": getDeviceToken(),
    "is-from-admin": is_from_admin,
    device: "web",
    lang: lang ?? "sr-RS",
    "screen-code": screen_code || "",
    Authorization: `Bearer ${token}`,
  };

  let pathname = "";
  let search = "";
  if (typeof window !== "undefined") {
    pathname = window.location.pathname;
    search = window.location.search;
  }

  const handleLogout = (pathname = null, search = null, data) => {
    if (typeof window !== "undefined") {
      Cookies.remove("scoring_user");
      Cookies.remove("scoring_token");
      const device_token = Cookies.get("device-token");
      Cookies.set("customer-token", device_token, { expires: 365 });
      Cookies.remove("isFromAdmin");
      if (pathname) {
        window.location.href = `/login?back_url=${pathname}${search}`;
      } else {
        window.location.href = "/login";
      }
    }
  };

  const api = "https://api.scoring.croonus.com/api/v1/";
  const local = "http://192.168.1.113:8000/api/v1/";

  try {
    const response = await axios({
      method: method,
      url: api + path.replace(/^\//, ""),
      headers: headers,
      data: payload,
    });
    return response?.data;
  } catch (err) {
    switch (err?.response?.status) {
      case 401:
        path !== "/portal/sign-in/login" && handleLogout(pathname, search);
        break;
      default:
        break;
    }
    return err?.response;
  }
};

export const get = async (path, screen_code) => {
  return makeRequest("GET", path, null, screen_code);
};

export const put = async (path, payload) => {
  return makeRequest("PUT", path, payload);
};

export const post = async (path, payload, screen_code) => {
  return makeRequest("POST", path, payload, screen_code);
};

export const list = async (path, payload) => {
  return makeRequest("LIST", path, payload);
};

export const deleteMethod = async (path) => {
  return makeRequest("DELETE", path);
};
