import { useMutation, useQuery } from "@tanstack/react-query";
import { get, post } from "./api";
import { useContext, useEffect, useState } from "react";
import { userContext } from "./userContext";

export const useFilterNames = (ddl_slug, value) => {
  return useQuery({
    queryKey: ["ddl_slug", ddl_slug, value],
    queryFn: async () => {
      if (ddl_slug === "addresses") {
        return await get(
          `portal/companies/search/company/basic/ddl/${ddl_slug}?search=${value}`,
        ).then((res) => res?.payload);
      }

      if (ddl_slug === "activities") {
        return await get(
          `portal/companies/search/company/basic/ddl/${ddl_slug}?id=${value}`,
        ).then((res) => res?.payload);
      } else {
        return await get(
          `portal/companies/search/company/basic/ddl/${ddl_slug}?id=${value}`,
        ).then((res) => res?.payload);
      }
    },
  });
};

export const useTranslate = (page, key) => {
  const { lang } = useContext(userContext);

  const { data, mutate, isPending, isSuccess } = useMutation({
    mutationKey: [
      `${key}`,
      {
        lang: lang,
      },
    ],
    mutationFn: async () => {
      return await post(
        `/portal/translations/list`,
        {
          page: page,
        },
        "TRANSLATIONS_LIST",
      )
        .then((res) => res?.payload)
        ?.catch((err) => console.log(err));
    },
  });

  useEffect(() => {
    mutate();
  }, [lang]);

  return { data, isPending, isSuccess };
};

export const useIsMobile = () => {
  const [is_mobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return is_mobile;
};
