import { useState, createContext, useEffect } from "react";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";

export const userContext = createContext({
  login: () => {},
  logout: () => {},
  lang: "sr",
  setLang: () => {},
  isLoggedIn: false,
  setIsLoggedIn: () => {},
  user: null,
  setUser: () => {},
});

export const AuthProvider = ({ children }) => {
  const navigate = useNavigate();
  const [lang, setLang] = useState("sr");
  const [user, setUser] = useState();
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const handleLogin = (user, token, back_url) => {
    const customer_token = user?.customer_token;

    if (user && typeof window !== "undefined") {
      Cookies.set("scoring_user", JSON.stringify(user));
      Cookies.set("scoring_token", token);
      Cookies.set("customer-token", customer_token, { expires: 365 });
      setIsLoggedIn(true);
      setUser(user);
      if (back_url) {
        navigate(back_url);
      } else {
        navigate("/dashboard");
      }
    }
  };
  const handleLogout = (pathname = null, search = null) => {
    if (typeof window !== "undefined") {
      Cookies.remove("scoring_user");
      Cookies.remove("scoring_token");
      Cookies.remove("customer-token");
      Cookies.remove("isFromAdmin");
      setIsLoggedIn(false);
      setUser(null);
      if (pathname) {
        navigate(`/login?back_url=${pathname}${search}`);
      } else {
        navigate("/login");
      }
    }
  };

  useEffect(() => {
    const user = Cookies.get("scoring_user");
    const token = Cookies.get("scoring_token");
    const device_token = Cookies.get("device-token");
    if (user && token) {
      setIsLoggedIn(true);
      setUser(JSON.parse(user));
    }
  }, []);

  useEffect(() => {
    let selected_lang = Cookies.get("selected_lang");
    if (selected_lang) {
      setLang(selected_lang);
    }
  }, [lang]);

  return (
    <userContext.Provider
      value={{
        login: handleLogin,
        logout: handleLogout,
        lang: lang,
        setLang: setLang,
        isLoggedIn: isLoggedIn,
        setIsLoggedIn: setIsLoggedIn,
        user: user,
        setUser: setUser,
      }}
    >
      {children}
    </userContext.Provider>
  );
};
